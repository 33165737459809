import type { AfCore } from "@olenbetong/appframe-core";

import * as courseStatus from "./course";
import * as invoiceStatus from "./invoice";
import * as issuesStatus from "./issues";

export type ArticleStatusItem = {
  description?: string;
  href: string;
  severity?: "danger" | "info" | "success" | "warning" | false;
  text: string;
};

export type ArticleStatus = {
  count: number;
  timestamp?: number;
  details:
    | false
    | {
        items?: ArticleStatusItem[];
        title: string;
      };
};

export type ArticleStatusProvider = {
  articleId: string;
  getStatus: () => Promise<ArticleStatus>;
};

class MittEmitter {
  constructor(defs: any) {
    // @ts-ignore
    this.defs = defs;
    // @ts-ignore
    this.mitt = af.common.mitt();
    // @ts-ignore
    this.attach = this.mitt.on;
    // @ts-ignore
    this.detach = this.mitt.off;
    // @ts-ignore
    this.fire = this.mitt.emit;
  }
}

let cache: Record<string, ArticleStatus & { timestamp: number }> = {};
let events = {
  onStatusUpdated: { abortable: false },
};
// @ts-ignore
let eventHandler = af.common.EventHandler
  ? // @ts-ignore
    new af.common.EventHandler(globalThis, events)
  : new MittEmitter(events);

function addArticleBadges(articleId: string, count: number) {
  var menuBadge = document.createElement("span");
  menuBadge.className = "badge badge-pill badge-danger ml-auto";
  menuBadge.innerText = String(count);

  var menuLink = document.querySelector(`.c-sidebar [href*=${articleId}]`);
  if (menuLink) {
    menuLink.classList.add("d-flex");
    menuLink.append(menuBadge);
  }
}

function renderArticleStatus(articleId: string, status?: ArticleStatus) {
  if (status) {
    if (status && status.count) {
      addArticleBadges(articleId, status.count);
    }

    cache[articleId] = { ...status, timestamp: status.timestamp ?? Date.now() };

    // add timestamp if status wasn't fetched from cache
    if (!cache[articleId].timestamp) {
      cache[articleId].timestamp = Date.now();
    }
  }
}

async function getArticleStatus(article: ArticleStatusProvider) {
  const { articleId, getStatus } = article;
  const links = document.querySelectorAll(
    `.c-sidebar [href*=${articleId}], #MyPortalApps [href*=${articleId}]`
  );

  if (articleId !== af.article.id) {
    if (links.length > 0) {
      let status = await getStatus();
      renderArticleStatus(articleId, status);
    }
  } else {
    delete cache[articleId];
  }
}

function doStatusChecks() {
  Promise.all([
    getArticleStatus(courseStatus),
    getArticleStatus(invoiceStatus),
    getArticleStatus(issuesStatus),
  ]).then(() => {
    eventHandler.fire("onStatusUpdated");
  });
}

af.common.expose("ob.status.eventHandler", eventHandler);
af.common.expose("ob.status.getStatus", function () {
  return { ...cache };
});

document.addEventListener("DOMContentLoaded", doStatusChecks);
